<!--
 * @Descripttion:
 * @version:
 * @Author: peter
 * @Date: 2021-09-17 17:16:11
 * @LastEditors: peter
 * @LastEditTime: 2021-09-23 11:04:07
-->
<template>
  <div>
    <el-card>
      <div class="form-wrap">
        <el-form ref="ruleForm" :model="form" :rules="rules" label-width="180px" size="small">
          <el-form-item
            label="商户号："
            prop="mchId"
          >
            <el-input v-model="form.mchId" placeholder="请输入商户号" />
          </el-form-item>
          <el-form-item
            label="商户证书序列号："
            prop="mchSerialNo"
          >
            <el-input v-model="form.mchSerialNo" placeholder="请输入商户证书序列号" />
          </el-form-item>
          <el-form-item
            label="apiv3密钥"
            prop="apiV3Key"
          >
            <el-input v-model="form.apiV3Key" placeholder="请输入apiv3密钥" />
          </el-form-item>
          <el-form-item
            label="绑定的小程序ID："
            prop="appId"
          >
            <el-input v-model="form.appId" placeholder="请输入绑定的小程序ID" />
          </el-form-item>
          <el-form-item
            label="api证书私钥："
            prop="apiClientPem"
          >
            <el-input v-model="form.apiClientPem" type="textarea" placeholder="请输入api证书私钥" />
            <div>请把证书下载下来，然后把证书文件里面的字符串复制过来</div>
          </el-form-item>
          <el-form-item
            label="支付完成回调地址："
            prop="notifyUrl"
          >
            <el-input v-model="form.notifyUrl" placeholder="请输入支付完成回调地址" />
          </el-form-item>
          <el-form-item>
            <el-button>取消</el-button>
            <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import { savePayOptions, getPayOptions } from '../../utils/api'
export default {
  data() {
    return {
      form: {
        mchId: '',
        mchSerialNo: '',
        apiV3Key: '',
        appId: '',
        apiClientPem: '',
        notifyUrl: ''
      },
      rules: {
        mchId: [
          { required: true, message: '请输入商户号', trigger: 'blur' }
        ],
        mchSerialNo: [
          { required: true, message: '请输入商户证书序列号', trigger: 'blur' }
        ],
        apiV3Key: [
          { required: true, message: '请输入apiv3密钥', trigger: 'blur' }
        ],
        appId: [
          { required: true, message: '请输入绑定的小程序ID', trigger: 'blur' }
        ],
        apiClientPem: [
          { required: true, message: '请输入api证书私钥', trigger: 'blur' }
        ],
        notifyUrl: [
          { required: true, message: '请输入支付完成回调地址', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.getPayData()
  },
  methods: {
    async getPayData() {
      const res = await getPayOptions()
      if (res.success) {
        this.form = { ...res.data }
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          const res = await savePayOptions({ ...this.form })
          if (res.success) {
            this.$message.success('保存成功')
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped>
  .form-wrap {
    width: 600px;
  }
</style>
